import 'leaflet/dist/leaflet.css'
import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import L from 'leaflet'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  poiList?: PoiProps[]
}

export const PoiMap = memo(function PoiMap({
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  poiList,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    animationEnded(s) {
      if (map && poiList) {
        const lat = poiList[s.track.details.rel].latitude
        const lng = poiList[s.track.details.rel].longitude

        // @ts-ignore
        map.current?.flyTo([lat, lng])
      }
    },
  })

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [84, 106],
        iconAnchor: [42, 106],
        html: `<span>Hotel Lux</span><svg xmlns="http://www.w3.org/2000/svg" width="46" height="8" viewBox="0 0 46 8"><g transform="translate(-746 -4409.618)"><path d="M1869.081,1503.017a.182.182,0,0,0-.1-.312l-2.583-.375a.181.181,0,0,1-.137-.1l-1.156-2.341a.182.182,0,0,0-.327,0l-1.156,2.341a.181.181,0,0,1-.137.1l-2.583.375a.182.182,0,0,0-.1.312l1.869,1.822a.183.183,0,0,1,.052.162l-.441,2.573a.183.183,0,0,0,.265.193l2.311-1.215a.182.182,0,0,1,.17,0l2.311,1.215a.183.183,0,0,0,.265-.193l-.441-2.573a.183.183,0,0,1,.052-.162Z" transform="translate(-1114.743 2909.83)" /><path d="M1869.081,1503.017a.182.182,0,0,0-.1-.312l-2.583-.375a.181.181,0,0,1-.137-.1l-1.156-2.341a.182.182,0,0,0-.327,0l-1.156,2.341a.181.181,0,0,1-.137.1l-2.583.375a.182.182,0,0,0-.1.312l1.869,1.822a.183.183,0,0,1,.052.162l-.441,2.573a.183.183,0,0,0,.265.193l2.311-1.215a.182.182,0,0,1,.17,0l2.311,1.215a.183.183,0,0,0,.265-.193l-.441-2.573a.183.183,0,0,1,.052-.162Z" transform="translate(-1095.939 2909.83)" /><path d="M1869.081,1503.017a.182.182,0,0,0-.1-.312l-2.583-.375a.181.181,0,0,1-.137-.1l-1.156-2.341a.182.182,0,0,0-.327,0l-1.156,2.341a.181.181,0,0,1-.137.1l-2.583.375a.182.182,0,0,0-.1.312l1.869,1.822a.183.183,0,0,1,.052.162l-.441,2.573a.183.183,0,0,0,.265.193l2.311-1.215a.182.182,0,0,1,.17,0l2.311,1.215a.183.183,0,0,0,.265-.193l-.441-2.573a.183.183,0,0,1,.052-.162Z" transform="translate(-1077.136 2909.83)" /></g></svg>`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      {poiList ? (
        <PoiList>
          <Slider ref={sliderRef}>
            {uniq(poiList).map((item, index) => (
              <Poi
                className={`keen-slider__slide${
                  currentSlide === index ? ' active' : ''
                }`}
                key={index}
                {...item}
              />
            ))}
          </Slider>

          {poiList.length > 1 ? (
            <Arrows row>
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                variant="small"
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                variant="small"
              />
            </Arrows>
          ) : null}
        </PoiList>
      ) : null}

      <Map>
        {typeof window !== undefined && latitude && longitude ? (
          <MapContainer
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={15}
            scrollWheelZoom={false}
            whenCreated={(mapInstance: any) => {
              map.current = mapInstance
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            />
            {poiList
              ? uniq(poiList).map((item, index) => (
                  <Marker
                    key={index}
                    icon={
                      typeof window !== 'undefined'
                        ? L.divIcon({
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                            className:
                              currentSlide === index ? 'active' : undefined,
                          })
                        : undefined
                    }
                    position={[
                      Number(item.latitude) || 0,
                      Number(item.longitude) || 0,
                    ]}
                  />
                ))
              : null}
          </MapContainer>
        ) : null}

        {googleMapsURL ? (
          <GoogleMapsCTA href={googleMapsURL} rel="noreferrer" target="_blank">
            {useVocabularyData('open-in-google-maps', languageCode)}
          </GoogleMapsCTA>
        ) : null}
      </Map>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 9.375rem;
  position: relative;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
  }
`

const PoiList = styled.div`
  max-width: 34.375rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  padding: 2.5rem 3.125rem 1.875rem 5vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 410;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Slider = styled.div`
  width: calc(100% - 5.625rem);
  display: flex;
  overflow: hidden;
  position: relative;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 3.125rem;
  transform: translateY(-50%);

  > div {
    background: none;
    &:hover {
      svg {
        stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
`

const Map = styled.div`
  height: 77.8vh;
  margin-top: 3.9375rem;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .leaflet-marker-icon {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    border: 0;
    border-radius: 50%;
    opacity: 0.4;
    &.main-icon {
      background: none;
      border-radius: 0;
      opacity: 1;
      padding: 1.1875rem;
      position: relative;
      text-align: center;
      &:before {
        content: '';
        width: 100%;
        height: calc(100% - 0.75rem);
        position: absolute;
        background: ${({ theme }) => theme.colors.variants.primaryLight};
        top: 0;
        left: 0;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 0.625rem solid transparent;
        border-right: 0.625rem solid transparent;
        border-top: 0.75rem solid
          ${({ theme }) => theme.colors.variants.primaryLight};
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        font-size: 0.9375rem;
        line-height: 1rem;
        position: relative;
        z-index: 2;
        text-transform: uppercase;
      }
      svg {
        fill: ${({ theme }) => theme.colors.variants.neutralLight4};
        margin-top: 0.625rem;
        position: relative;
        z-index: 2;
      }
    }
    &.active {
      opacity: 1;
    }
  }

  .leaflet-left {
    top: auto;
    right: 2.5rem;
    bottom: 5rem;
    left: auto;
    z-index: 400;
    .leaflet-control {
      display: flex;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border: 0;
        border-radius: 50% !important;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.neutralLight3};
          }
          &:before {
            content: '';
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark3};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          margin-right: 0.75rem;
          &:after {
            content: '';
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark3};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;
          &:hover {
            opacity: 0.2;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1023px) {
    height: 50vh;

    .leaflet-marker-icon {
      display: none;
      &.main-icon {
        display: block;
      }
    }

    .leaflet-left {
      display: none;
    }
  }
`

const GoogleMapsCTA = styled.a`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 2rem;
  padding: 0.75rem 2.125rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: 0.2s ease-in-out;
  z-index: 400;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`
