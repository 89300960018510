import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon: string
}

export const TabIcon = memo(function TabIcon({ icon }: Props) {
  const SVG = () => {
    switch (icon) {
      case 'train':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.803"
            height="24"
            viewBox="0 0 22.803 24"
          >
            <path
              d="M-16660.338-2251.331l-.432-.869h-19.66l-.436.869a.6.6,0,0,1-.539.332.566.566,0,0,1-.264-.065.6.6,0,0,1-.273-.8l2.4-4.8a.607.607,0,0,1,.809-.271.606.606,0,0,1,.266.8l-.164.332h16.055l-.158-.332a.6.6,0,0,1,.264-.8.6.6,0,0,1,.8.271l2.4,4.8a.6.6,0,0,1-.271.8.562.562,0,0,1-.266.065A.6.6,0,0,1-16660.338-2251.331Zm-19.494-2.07h18.465l-.6-1.2h-17.258Zm3.232-3.6a3,3,0,0,1-3-3v-12a3,3,0,0,1,3-3h12a3,3,0,0,1,3,3v12a3,3,0,0,1-3,3Zm-1.8-15v12a1.8,1.8,0,0,0,1.8,1.8h12a1.8,1.8,0,0,0,1.8-1.8v-12a1.8,1.8,0,0,0-1.8-1.8h-12A1.8,1.8,0,0,0-16678.4-2272Zm10.8,10.8a1.8,1.8,0,0,1,1.8-1.8,1.8,1.8,0,0,1,1.8,1.8,1.8,1.8,0,0,1-1.8,1.8A1.8,1.8,0,0,1-16667.6-2261.2Zm1.2,0a.6.6,0,0,0,.6.6.6.6,0,0,0,.6-.6.6.6,0,0,0-.6-.6A.6.6,0,0,0-16666.4-2261.2Zm-10.8,0a1.8,1.8,0,0,1,1.8-1.8,1.8,1.8,0,0,1,1.8,1.8,1.8,1.8,0,0,1-1.8,1.8A1.8,1.8,0,0,1-16677.2-2261.2Zm1.2,0a.594.594,0,0,0,.6.6.6.6,0,0,0,.6-.6.6.6,0,0,0-.6-.6A.6.6,0,0,0-16676-2261.2Zm-.6-3a.6.6,0,0,1-.6-.6v-4.8a.6.6,0,0,1,.6-.6h12a.6.6,0,0,1,.6.6v4.8a.6.6,0,0,1-.6.6Zm6.6-1.2h4.8v-3.6h-4.8Zm-6,0h4.8v-3.6h-4.8Zm1.8-6a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6h7.2a.6.6,0,0,1,.6.6.6.6,0,0,1-.6.6Z"
              transform="translate(16682.002 2274.999)"
              stroke-width="0.5"
            />
          </svg>
        )
      case 'plane':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.58"
            height="25"
            viewBox="0 0 27.58 25"
          >
            <path
              d="M15.108,30.129H12.314a.416.416,0,0,1-.415-.416.4.4,0,0,1,.034-.163l3.844-8.967H11.145L7.611,23.564a.416.416,0,0,1-.267.1H5.494a.415.415,0,0,1-.415-.413.408.408,0,0,1,.026-.142l1.8-4.975L5.1,13.152a.415.415,0,0,1,.248-.531.432.432,0,0,1,.142-.024H7.344a.416.416,0,0,1,.267.1l3.534,2.984h4.632L11.933,6.708a.415.415,0,0,1,.218-.545.421.421,0,0,1,.163-.034h2.795a.411.411,0,0,1,.326.159l7.345,9.39h4.736c3.613,0,4.09,2.278,4.109,2.374a.425.425,0,0,1,0,.157c-.018.1-.5,2.374-4.109,2.374H22.779L15.434,29.97A.411.411,0,0,1,15.108,30.129ZM12.943,29.3h1.962l7.345-9.386a.418.418,0,0,1,.327-.159h4.938c2.568,0,3.161-1.29,3.271-1.623-.111-.338-.7-1.624-3.271-1.624H22.578a.415.415,0,0,1-.327-.159l-7.345-9.39H12.943l3.844,8.971a.415.415,0,0,1-.218.545.422.422,0,0,1-.164.034H10.993a.423.423,0,0,1-.267-.1L7.192,13.424H6.086l1.648,4.565a.416.416,0,0,1,0,.282L6.086,22.834H7.192l3.534-2.981a.416.416,0,0,1,.267-.1h5.412a.414.414,0,0,1,.415.415.425.425,0,0,1-.033.164Z"
              transform="translate(-4.552 -5.629)"
              stroke-width="1"
            />
          </svg>
        )

      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="21.154"
            viewBox="0 0 25 21.154"
          >
            <path
              d="M-16523.293-2322.85a1.44,1.44,0,0,1-1.436-1.442v-2.4h-10.557v2.39a1.436,1.436,0,0,1-1.43,1.45h-2.875a1.45,1.45,0,0,1-1.451-1.45v-2.649a1.926,1.926,0,0,1-.959-1.661v-4.931a.354.354,0,0,1,0-.143,2.94,2.94,0,0,1,2.572-2.553l1.916-5.457a1.926,1.926,0,0,1,1.844-1.306h11.8a1.926,1.926,0,0,1,1.9,1.326l1.92,5.564a2.876,2.876,0,0,1,2.031,2.426.441.441,0,0,1,0,.143v4.931a1.919,1.919,0,0,1-.961,1.661v2.657a1.44,1.44,0,0,1-1.437,1.442Zm-16.787-1.45a.5.5,0,0,0,.49.49h2.875a.482.482,0,0,0,.471-.49v-2.39h-3.836Zm16.311,0a.479.479,0,0,0,.477.478h2.881a.479.479,0,0,0,.479-.478v-2.39h-3.836Zm-17.271-9v4.688a.96.96,0,0,0,.961.96h20.146a.957.957,0,0,0,.959-.96v-4.688a1.232,1.232,0,0,0-.008-.124,2.08,2.08,0,0,0-2.246-1.9h-17.557c-.041,0-.082,0-.125,0h-.051A2.085,2.085,0,0,0-16541.041-2333.3Zm4.475-8.062-1.854,5.075h17.271l-1.756-5.094a.966.966,0,0,0-.961-.661h-11.8A.954.954,0,0,0-16536.566-2341.36Zm12.8,9.875a1.918,1.918,0,0,1,1.92-1.92,1.918,1.918,0,0,1,1.916,1.92,1.918,1.918,0,0,1-1.916,1.92A1.918,1.918,0,0,1-16523.77-2331.485Zm.961,0a.958.958,0,0,0,.959.96.957.957,0,0,0,.957-.96.957.957,0,0,0-.957-.96A.957.957,0,0,0-16522.809-2331.485Zm-12,1.92a.481.481,0,0,1-.48-.482v-2.88a.48.48,0,0,1,.48-.478h9.6a.481.481,0,0,1,.479.478v2.88a.481.481,0,0,1-.479.482Zm.48-.96h8.635v-1.92h-8.635Zm-5.756-.96a1.918,1.918,0,0,1,1.92-1.92,1.918,1.918,0,0,1,1.916,1.92,1.918,1.918,0,0,1-1.916,1.92A1.918,1.918,0,0,1-16540.08-2331.485Zm.959,0a.961.961,0,0,0,.961.96.96.96,0,0,0,.959-.96.96.96,0,0,0-.959-.96A.96.96,0,0,0-16539.121-2331.485Z"
              transform="translate(16542.508 2343.503)"
              stroke-width="0.5"
            />
          </svg>
        )
    }
  }

  return <Icon>{SVG()}</Icon>
})

const Icon = styled.div`
  width: auto;
  height: 1.25rem;
  margin-right: 1rem;
  fill: ${({ theme }) => theme.colors.variants.neutralDark4};
  stroke: ${({ theme }) => theme.colors.variants.neutralDark4};

  @media (max-width: 767px) {
    margin-right: 0.75rem;
  }

  @media (max-width: 474px) {
    display: none;
  }
`
